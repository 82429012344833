import {useEffect, useState} from "react";
import {getUserAccount} from "../../utils/AppWrite";

export default function Header() {

    const [userName, setUserName] = useState("");

    useEffect(() => {
        getUserAccount().then(accountData => {
            setUserName(accountData.name)
        })
    }, [])

    return (
        <div className='flex px-10 mt-6 items-center'>
            <div className='grow'>
                <img src="/fixale.png" alt="Logo" className="h-6"/></div>
            <div className="flex items-center gap-4">
                <h3>{userName}</h3>
                <img src="/fixale.png" alt="Profile pic" className='h-9 w-9 rounded-full' />
            </div>

        </div>);
}