import SubscriptionElement from "../../components/SubscriptionElement";
import React, { useEffect, useState } from "react";
import { Client, Databases, Query } from "appwrite";
import { getUserAccount, getUserSubscriptions } from "../../utils/AppWrite";
import { Snackbar } from "@mui/material";

export default function Subscription() {

    const [userSubscriptionData, setUserSubscriptionData] = useState([]);

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    useEffect(() => {
        getUserSubscriptions().then((subscriptionData => {
            setUserSubscriptionData(subscriptionData)
        })).catch(error => {
            handleClick({
                vertical: 'bottom',
                horizontal: 'left',
            })
        })
    }, [])

    return (<>
        <div className='px-10 lg:px-20 xl:px-40 2xl:px-96 mt-10'>
            <h3 className='text-md font-bold mb-2'>Your Subscriptions</h3>
            <div className='flex flex-col gap-8 divide-y divide-solid'>
                {userSubscriptionData.map((userSubscriptionDetail, index) => (
                    <SubscriptionElement key={userSubscriptionDetail.$id}
                        subscriptionId={index}
                        orderId={userSubscriptionDetail.OrderId}
                        productId={userSubscriptionDetail.ProductId}
                        startingDate={userSubscriptionDetail.StartingDate}
                        endingDate={userSubscriptionDetail.EndingDate}
                        cancelled={userSubscriptionDetail.Cancelled}
                    />
                ))}
            </div>
        </div>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Error getting subscription data"
            key={vertical + horizontal}
        />
    </>);
}