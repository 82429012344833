import React, { useEffect, useState, useRef } from 'react'
import { RadioGroup } from '@headlessui/react'
import { Switch } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import posthog from 'posthog-js'

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {
    getProductData,
    getUserAccount,
    getUserData,
    getUserLocale, preparePaymentBackend,
    validateOrderRequestToken
} from "../utils/AppWrite";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Account, Client, Databases } from "appwrite";
import { Dialog, DialogContent, Snackbar } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const deliveryMethods = [
    { id: 1, title: 'Monthly', turnaround: 'Subscribe for a Month' }
]

let acceptedPaymentMethods = [
    { id: 'instamojo', title: 'Instamojo', acceptedCurrencies: ["INR"], description: "Accepts All Indian Payment Methods: UPI, Domestic Debit/Credit Cards and Wallets." },
    { id: 'stripe', title: 'Stripe', acceptedCurrencies: ["INR", "USD", "BDT"], description: "Accepts Mostly International Debit/Credit Cards" },
    { id: 'dannypay', title: 'DannyPay', acceptedCurrencies: ["BDT"], description: "Bangladesh Payment Methods like Bkash, Nagad, Rocket" }
]

let paymentMethods = []

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Checkout() {

    const [urlParams, setUrlParams] = useSearchParams();

    const [orderRequestToken, setOrderRequestToken] = useState("");

    const [userAccountData, setUserAccountData] = useState({});

    const [orderRequestTokenData, setOrderRequestTokenData] = useState({});

    const [productsData, setProductsData] = useState([]);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [tnc, setTnc] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const [userCheckoutData, setUserCheckoutData] = useState({
        Email: "",
        FirstName: "",
        Address: "",
        Address2: "",
        City: "",
        Country: "",
        State: "",
        PostalCode: "",
        Phone: "",
        SubscriptionType: "Monthly",
        PaymentMethod: "",
        Currency: ""
    });

    const [paymentAmountData, setPaymentAmountData] = useState({
        Subtotal: 0,
        Discount: 0,
        Total: 0
    });

    useEffect(() => {
        posthog.init('phc_ziuer7Yft4iJIH9cNqRaXN3pmMQzWdOCrnluN7BEzoT', {
            api_host: 'https://fixhog.fixale.com/',
            ui_host: 'https://app.posthog.com'
        });

        getUserAccount().then(userData => {
            setUserAccountData(userData);
        }).catch(e => {
            window.location.href = "https://myaccount.fixale.com?next=/payments"
        })
    }, [])

    useEffect(() => {
        if (urlParams.get("orderRequest")) {
            posthog.identify(
                userAccountData.$id, { email: userAccountData.email }
            );

            if (orderRequestTokenData.userEmail) {
                return
            }

            validateOrderRequestToken(urlParams.get("orderRequest"), (orderRequestTokenResponse) => {
                if (orderRequestTokenResponse.status === "success") {
                    setOrderRequestToken(urlParams.get("orderRequest"));
                    setOrderRequestTokenData(orderRequestTokenResponse.orderRequestData)
                } else {
                    toast.error("Order request data failed", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
        } else {
            toast.error("Error getting order data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [userAccountData.$id])

    useEffect(() => {
        if (!userAccountData.$id) {
            return
        }

        posthog.capture('user_opened_checkout');

        getUserData(userAccountData.$id).then(userData => {
            if (!userData.BillingCountry || !userData.BillingRegion) {
                getUserLocale().then(userLocale => {
                    setUserCheckoutData({
                        ...userCheckoutData,
                        Address: userData.BillingAddress,
                        Address2: userData.BillingAddress2,
                        Country: userLocale.country,
                        Email: userAccountData.email,
                        FirstName: userAccountData.name,
                        Phone: userAccountData.phone
                    })
                    setLoading(false);
                }).catch(e => {

                })
            } else {
                setUserCheckoutData({
                    ...userCheckoutData,
                    Address: userData.BillingAddress,
                    Address2: userData.BillingAddress2,
                    State: userData.BillingRegion,
                    Country: userData.BillingCountry,
                    Email: userAccountData.email,
                    FirstName: userAccountData.name,
                    Phone: userAccountData.phone
                })
                setLoading(false);
            }
        }).catch(e => {
            toast.error("Failed to Get User Data", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }, [orderRequestTokenData]);

    useEffect(() => {
        if (!userCheckoutData.Country) {
            return
        }

        let tempProductsData = []

        for (const product of orderRequestTokenData.products) {
            getProductData(product, userCheckoutData.Country).then(productDataResponse => {
                if (productDataResponse.status === "success") {
                    tempProductsData.push(productDataResponse.productData);

                    if (tempProductsData.length === orderRequestTokenData.products.length) {
                        paymentMethods = acceptedPaymentMethods.filter((paymentMethod) => paymentMethod.acceptedCurrencies.includes(tempProductsData[0].Currency) )
                        setProductsData(tempProductsData)
                        setUserCheckoutData({ ...userCheckoutData, Currency: tempProductsData[0].Currency })
                    }
                } else {
                    toast.error("Failed To Fetch Product Data", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch(e => {
                toast.error("Failed To Fetch Product Data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        }
    }, [userCheckoutData.Country])


    useEffect(() => {
        if (productsData.length < 1) {
            return
        }
        calculateTotalPricing();
    }, [productsData])

    useEffect(() => {
        if (!userCheckoutData.SubscriptionType) {
            return
        }

        calculateTotalPricing();
    }, [userCheckoutData.SubscriptionType])

    function calculateTotalPricing() {
        if (productsData.length < 0) {
            return
        }

        let paymentSubTotal = 0.00;
        let paymentDiscount = 0.00;
        let paymentTotal = 0.00;

        for (const tmpProductData of productsData) {

            if (tmpProductData.YearlyPrice) {
                deliveryMethods.push({ id: 2, title: 'Yearly', turnaround: 'Subscribe for a Year and Save 20%' });
            }

            let productPrice;

            if (userCheckoutData.SubscriptionType === "Monthly") {
                productPrice = parseFloat(tmpProductData.MonthlyPrice).toFixed(2);
            } else {
                productPrice = parseFloat(tmpProductData.YearlyPrice).toFixed(2);
            }

            let productDiscount = tmpProductData.Discount

            paymentSubTotal = +paymentSubTotal + +productPrice
            paymentDiscount = +paymentDiscount + +productDiscount

            paymentTotal = paymentSubTotal - paymentDiscount;
        }
        setPaymentAmountData({ Subtotal: paymentSubTotal, Discount: paymentDiscount, Total: paymentTotal });
    }

    function confirmOrder(e) {
        e.preventDefault()
        if (fillTheRequiredField()) {
            return;
        }
        setCheckoutLoading(true);
        if (userCheckoutData.PaymentMethod.toLowerCase() === "stripe") {
            preparePaymentBackend(orderRequestToken, userCheckoutData).then(response => {
                if (response.status === "success") {
                    if (response.paymentMethodData.checkoutUrl) {
                        window.location.replace(response.paymentMethodData.checkoutUrl)
                    }
                } else {
                    toast.error("Error confirming order", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setCheckoutLoading(false);
            }).catch(e => {
                toast.error("Error confirming order", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        } else if (userCheckoutData.PaymentMethod.toLowerCase() === "instamojo") {
            preparePaymentBackend(orderRequestToken, userCheckoutData).then(response => {
                if (response.status === "success") {
                    if (response.paymentMethodData.longUrl) {
                        window.location.replace(response.paymentMethodData.longUrl)
                    }
                } else {
                    toast.error("Error confirming order", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setCheckoutLoading(false);
            }).catch(e => {
                toast.error("Error confirming order", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        } else if (userCheckoutData.PaymentMethod.toLowerCase() === "paypal") {
            preparePaymentBackend(orderRequestToken, userCheckoutData).then(response => {
                if (response.status === "success") {
                    if (response.paymentMethodData.checkoutUrl) {
                        window.location.replace(response.paymentMethodData.checkoutUrl)
                    }
                } else {
                    toast.error("Error confirming order", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setCheckoutLoading(false);
            }).catch(e => {
                toast.error("Error confirming order", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        } else if (userCheckoutData.PaymentMethod.toLowerCase() === "dannypay") {
            preparePaymentBackend(orderRequestToken, userCheckoutData).then(response => {
                if (response.status === "success") {
                    if (response.paymentMethodData.paymentLink) {
                        window.location.replace(response.paymentMethodData.paymentLink)
                    }
                } else {
                    toast.error("Error confirming order", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                setCheckoutLoading(false);
            }).catch(e => {
                toast.error("Error confirming order", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        }
    }
    function fillTheRequiredField() {

        if (userCheckoutData.Email === "") {
            toast.warn("Fill required field 'email'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.FirstName === "") {
            toast.warn("Fill required field 'name'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.Address === "") {
            toast.warn("Fill required field 'address'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.Address2 === "") {
            toast.warn("Fill required field 'apartment'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.City === "") {
            toast.warn("Fill required field 'city'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.Country === "") {
            toast.warn("Fill required field 'country'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.State === "") {
            toast.warn("Fill required field 'state'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.PostalCode === "") {
            toast.warn("Fill required field 'code'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.Phone === "") {
            toast.warn("Fill required field 'phone'", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (userCheckoutData.PaymentMethod === "") {
            toast.warn("Select the payment method before checkout", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else if (!tnc) {
            toast.warn("Accept the return policy", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="bg-gray-50">
                <div className="max-w-2xl mx-auto pt-2 md:pt-4 lg:pt-10 xl:pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <img src="/fixale.png" alt="Fixale Logo" className='h-6 mb-6' />
                    <h2 className="sr-only">Checkout</h2>

                    <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                        <div>
                            <div>
                                <h2 className="text-lg font-medium text-gray-900">Contact information</h2>
                                <ToastContainer />
                                <div className="mt-4">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                        Email Address
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="email"
                                            id="email-address"
                                            value={userCheckoutData.Email}
                                            onChange={(e) => { setUserCheckoutData({ ...userCheckoutData, Email: e.target.value }) }}
                                            name="email-address"
                                            autoComplete="email"
                                            disabled={true}
                                            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10 border-t border-gray-200 pt-10">
                                <h2 className="text-lg font-medium text-gray-900">Shipping information</h2>

                                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                    <div>
                                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                id="first-name"
                                                name="first-name"
                                                value={userCheckoutData.FirstName}
                                                onChange={(e) => { setUserCheckoutData({ ...userCheckoutData, FirstName: e.target.value }) }}
                                                autoComplete="given-name"
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                            Address
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                value={userCheckoutData.Address}
                                                onChange={(e) => { setUserCheckoutData({ ...userCheckoutData, Address: e.target.value }) }}
                                                autoComplete="street-address"
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="apartment" className="block text-sm font-medium text-gray-700">
                                            Apartment, suite, etc.
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="apartment"
                                                id="apartment"
                                                value={userCheckoutData.Address2}
                                                onChange={(e) => { setUserCheckoutData({ ...userCheckoutData, Address2: e.target.value }) }}
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                            City
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                value={userCheckoutData.City}
                                                onChange={(e) => { setUserCheckoutData({ ...userCheckoutData, City: e.target.value }) }}
                                                autoComplete="address-level2"
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                            Country
                                        </label>
                                        <div className="mt-1">
                                            <CountryDropdown
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                                value={userCheckoutData.Country}
                                                valueType={"full"}
                                                onChange={(val) => { setUserCheckoutData({ ...userCheckoutData, Country: val }) }} />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                            State / Province
                                        </label>
                                        <div className="mt-1">
                                            <RegionDropdown
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                                valueType={"full"}
                                                country={userCheckoutData.Country}
                                                value={userCheckoutData.State}
                                                onChange={(val) => { setUserCheckoutData({ ...userCheckoutData, State: val }) }} />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                            Postal code
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="postal-code"
                                                id="postal-code"
                                                value={userCheckoutData.PostalCode}
                                                onChange={(e) => { setUserCheckoutData({ ...userCheckoutData, PostalCode: e.target.value }) }}
                                                autoComplete="postal-code"
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                            Phone
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                value={userCheckoutData.Phone}
                                                onChange={(e) => { setUserCheckoutData({ ...userCheckoutData, Phone: e.target.value }) }}
                                                autoComplete="tel"
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <Switch.Group>
                                        <div className="flex items-center">
                                            <Switch
                                                checked={enabled}
                                                onChange={setEnabled}
                                                className={`${enabled ? 'bg-accent' : 'bg-gray-200'
                                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                            >
                                                <span className="sr-only">Enable notifications</span>
                                                <span
                                                    className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                />
                                            </Switch>
                                            <Switch.Label className="ml-4 text-sm">Save credentials for next purchase</Switch.Label>
                                        </div>
                                    </Switch.Group>
                                    <Switch.Group>
                                        <div className="flex items-center">
                                            <Switch
                                                checked={tnc}
                                                onChange={setTnc}
                                                className={`${tnc ? 'bg-accent' : 'bg-gray-200'
                                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                            >
                                                <span className="sr-only">Accept T&C</span>
                                                <span
                                                    className={`${tnc ? 'translate-x-6' : 'translate-x-1'
                                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                />
                                            </Switch>
                                            <Switch.Label className="ml-4 text-sm">Accept return policy</Switch.Label>
                                        </div>
                                    </Switch.Group>
                                </div>
                            </div>


                        </div>

                        {/* Order summary */}
                        <div className="mt-10 lg:mt-0">
                            <h2 className="text-lg font-medium text-gray-900">Order summary</h2>

                            <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                                <h3 className="sr-only">Items in your cart</h3>
                                <ul role="list" className="divide-y divide-gray-200">
                                    {productsData.map((productData, index) => (
                                        <li key={index} className="flex py-6 px-4 sm:px-6">
                                            <div className="flex-shrink-0">
                                                <img src={productData.Image} alt={productData.Name} className="w-20 rounded-md" />
                                            </div>

                                            <div className="ml-6 flex-1 flex flex-col">
                                                <div className="flex">
                                                    <div className="min-w-0 flex-1">
                                                        <h4 className="text-sm">
                                                            <a href={productData.Url} className="font-medium text-gray-700 hover:text-gray-800">
                                                                {productData.Name}
                                                            </a>
                                                        </h4>
                                                        <p className="mt-1 text-sm text-gray-500">{userCheckoutData.SubscriptionType}</p>
                                                    </div>
                                                </div>

                                                <div className="flex-1 pt-2 flex items-end justify-between">
                                                    <div className='grid grid-cols-2 content-center'>
                                                        <div className='mt-1 text-sm font-medium text-gray-900'>{userCheckoutData.Currency}</div>
                                                        <div className="mt-1 text-sm font-medium text-gray-900">{(userCheckoutData.SubscriptionType === "Monthly") ? productData.MonthlyPrice : productData.YearlyPrice}</div>
                                                    </div>
                                                    <div className="ml-4">
                                                        <label htmlFor="quantity" className="sr-only">
                                                            Quantity
                                                        </label>
                                                        <select
                                                            id="quantity"
                                                            name="quantity"
                                                            disabled={true}
                                                            className="rounded-md border border-gray-300 text-base font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-accent focus:border-accent sm:text-sm"
                                                        >
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                            <option value={6}>6</option>
                                                            <option value={7}>7</option>
                                                            <option value={8}>8</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <dl className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <dt className="text-sm grow">Subtotal</dt>
                                        <div className='text-sm font-medium text-gray-900 mr-2'>{userCheckoutData.Currency}</div>
                                        <dd className="text-sm font-medium text-gray-900">{paymentAmountData.Subtotal}</dd>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <dt className="text-sm">Coupon code</dt>
                                        <input type="text" name="coupon-code" id="coupon-code" className='block border-gray-300 rounded-md shadow-sm focus:ring-accent focus:border-accent sm:text-sm' />
                                    </div>

                                    <div className="mt-10 border-t border-gray-200 pt-10">
                                        <RadioGroup value={userCheckoutData.SubscriptionType} onChange={(val) => { setUserCheckoutData({ ...userCheckoutData, SubscriptionType: val }) }}>
                                            <RadioGroup.Label className="text-lg font-medium text-gray-900">Subscription type</RadioGroup.Label>

                                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                {deliveryMethods.map((deliveryMethod) => (
                                                    <RadioGroup.Option
                                                        key={deliveryMethod.id}
                                                        value={deliveryMethod.title}
                                                        className={({ checked, active }) =>
                                                            classNames(
                                                                checked ? 'border-transparent' : 'border-gray-300',
                                                                active ? 'ring-2 ring-accent' : '',
                                                                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                                            )
                                                        }
                                                    >
                                                        {({ checked, active }) => (
                                                            <>
                                                                <div className="flex-1 flex">
                                                                    <div className="flex flex-col">
                                                                        <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                                            {deliveryMethod.title}
                                                                        </RadioGroup.Label>
                                                                        <RadioGroup.Description
                                                                            as="span"
                                                                            className="mt-1 flex items-center text-sm text-gray-500"
                                                                        >
                                                                            {deliveryMethod.turnaround}
                                                                        </RadioGroup.Description>
                                                                    </div>
                                                                </div>
                                                                {checked ? <CheckCircleIcon className="h-5 w-5 text-accent" aria-hidden="true" /> : null}
                                                                <div
                                                                    className={classNames(
                                                                        active ? 'border' : 'border-2',
                                                                        checked ? 'border-accent' : 'border-transparent',
                                                                        'absolute -inset-px rounded-lg pointer-events-none'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                            </>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                            </div>
                                        </RadioGroup>
                                    </div>

                                    {/* Payment */}
                                    <div className="mt-10 border-t border-gray-200 pt-10">
                                        <h2 className="text-lg font-medium text-gray-900">Payment</h2>

                                        <fieldset className="mt-4">
                                            <legend className="sr-only">Payment type</legend>
                                            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                                {paymentMethods.map((paymentMethod, paymentMethodIdx) => (
                                                    <div className='border rounded px-2 py-1'>
                                                        <div key={paymentMethod.id} className="flex items-center">
                                                            {paymentMethodIdx === 0 ? (
                                                                <input
                                                                    id={paymentMethod.id}
                                                                    name="payment-type"
                                                                    type="radio"
                                                                    onChange={() => { setUserCheckoutData({ ...userCheckoutData, PaymentMethod: paymentMethod.id }) }}
                                                                    className="focus:ring-accent h-4 w-4 text-accent border-gray-300"
                                                                />
                                                            ) : (
                                                                <input
                                                                    id={paymentMethod.id}
                                                                    name="payment-type"
                                                                    type="radio"
                                                                    onChange={() => { setUserCheckoutData({ ...userCheckoutData, PaymentMethod: paymentMethod.id }) }}
                                                                    className="focus:ring-accent h-4 w-4 text-accent border-gray-300"
                                                                />
                                                            )}

                                                            <label htmlFor={paymentMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                                                                {paymentMethod.title}
                                                            </label>
                                                        </div>
                                                        <label className=" block text-xs font-normal mt-1 text-gray-500">
                                                            {paymentMethod.description}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className="flex items-center border-t border-gray-200 justify-between pt-4">
                                        <dt className="text-sm grow">Discount</dt>
                                        <div className='text-sm font-medium text-gray-900 mr-2'>{userCheckoutData.Currency}</div>
                                        <dd className="text-sm font-medium text-gray-900">{paymentAmountData.Discount}</dd>
                                    </div>
                                    <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                                        <dt className="text-base font-medium grow">Total</dt>
                                        <div className='text-base font-medium text-gray-900 mr-2'>{userCheckoutData.Currency}</div>
                                        <dd className="text-base font-medium text-gray-900">{paymentAmountData.Total}</dd>
                                    </div>
                                </dl>

                                <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                                    <button disabled={checkoutLoading ? true : false}
                                        className="w-full bg-accent border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-accent" onClick={confirmOrder}
                                    >
                                        <svg style={{ display: checkoutLoading ? 'inline' : 'none', }} aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#FFFFFF"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#0AD7A5"></path>
                                        </svg>
                                        <h3 style={{ display: checkoutLoading ? 'none' : 'inline' }}>Confirm order</h3>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Dialog
                open={loading}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#FFFFFF"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#0AD7A5"></path>
                    </svg>
                </DialogContent>
            </Dialog>
        </>
    )
}
