import {Account, Storage, Client, ID, Databases, Avatars, Query, Locale, Functions} from "appwrite"
import axios from "axios";

const client = new Client()
    .setEndpoint(process.env.REACT_APP_FAW_ENDPOINT)
    .setProject(process.env.REACT_APP_FAW_PROJECT_ID);

const account = new Account(client);
const avatars = new Avatars(client);
const storage = new Storage(client);
const databases = new Databases(client);
const functions = new Functions(client);
const locale = new Locale(client);

export const getCurrentSession = async () => {
    try {
        return await account.getSession('current');
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserAccount = async () => {
    try {
        return await account.get()
    } catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserData = async (userId) => {
    try {
        return await databases.getDocument(process.env.REACT_APP_FAW_FIXALE_DATABASE_ID, process.env.REACT_APP_FAW_USER_DATA_COLLECTION_ID, userId);
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserLocale = async () => {
    try {
        return await locale.get()
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const createUserJwt = async () => {
    try {
        return await account.createJWT()
    } catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getProductData = async (productId, country = "India") => {
    try {
        let data = JSON.stringify({
            "product": productId,
            "country": country
        });

        const response = await functions.createExecution("64fbb70113acebb8203f",
            data, false, "/v1/getProductData", "POST", {'Content-Type': 'application/json'})
        return JSON.parse(response.responseBody);
    }catch (e){
        return {
            status: "failed"
        }
    }

}

export const getOrderProductsData = async (products) => {
    const productsData = [];
    for (const product of products) {
        try {
            productsData.push(await databases.getDocument(process.env.REACT_APP_FAW_FIXALE_DATABASE_ID, process.env.REACT_APP_FAW_PRODUCTS_COLLECTION_ID, product));
        }catch (appwriteError) {
            throw new Error(appwriteError)
        }
    }
    return productsData;
}


export const getUserSubscriptions = async () => {
    try {
        let userAccountData = await account.get();

        let subscriptionData = await databases.listDocuments(process.env.REACT_APP_FAW_FIXALE_DATABASE_ID, process.env.REACT_APP_FAW_SUBSCRIPTIONS_COLLECTION_ID, [Query.equal("UserId", userAccountData.$id)]);

        if (subscriptionData.total > 0) {
            return subscriptionData.documents;
        }else {
            return [];
        }
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserOrderData = async (orderId) => {
    try {
        let userAccountData = await account.get();

        let orderData = await databases.listDocuments(process.env.REACT_APP_FAW_FIXALE_DATABASE_ID, process.env.REACT_APP_FAW_ORDERS_COLLECTION_ID, [Query.equal("UserId", userAccountData.$id), Query.equal("OrderId", parseInt(orderId))]);

        if (orderData.total > 0) {
            return orderData.documents[0];
        }else {
            return undefined;
        }
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserOrders = async () => {
    try {
        let userAccountData = await account.get();

        let orderData = await databases.listDocuments(process.env.REACT_APP_FAW_FIXALE_DATABASE_ID, process.env.REACT_APP_FAW_ORDERS_COLLECTION_ID, [Query.equal("UserId", userAccountData.$id), Query.equal("PaymentStatus", ["success", "failed"])]);

        if (orderData.total > 0) {
            return orderData.documents;
        }else {
            return [];
        }
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const validateOrderRequestToken = async (orderRequestToken, callback = () => {}) => {
    try {
        let data = JSON.stringify({
            "orderRequestToken": orderRequestToken
        });

        const response = await functions.createExecution("64fbb70113acebb8203f",
            data, false, "/v1/validateOrderRequest", "POST", {'Content-Type': 'application/json'})
        callback(JSON.parse(response.responseBody));
    }catch (e) {
        callback({status: "failed"})
    }
}

export const preparePaymentBackend = async (orderRequestToken, userCheckoutData) => {
    try {
        let data = JSON.stringify({
            "orderRequestToken": orderRequestToken,
            "userCheckoutData": userCheckoutData
        });

        const response = await functions.createExecution("64fbb70113acebb8203f",
            data, false, "/v1/processPaymentMethod", "POST", {'Content-Type': 'application/json'})
        return JSON.parse(response.responseBody);
    }catch (e) {
        return {status: "failed"}
    }
}