import OrderElement from "../../components/OrderElement";
import React, { useEffect, useState } from "react";
import { getUserOrders } from "../../utils/AppWrite";
import { Snackbar } from "@mui/material";

export default function Orders() {

    const [userOrderData, setUserOrderData] = useState([]);
    const [productData, setProductData] = useState([])

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    useEffect(() => {
        getUserOrders().then(orderData => {
            setUserOrderData(orderData)
        }).catch(e => {
            handleClick({
                vertical: 'bottom',
                horizontal: 'left',
            })
        })
    }, [])

    return (
        <>
            <div className='px-10 lg:px-20 xl:px-40 2xl:px-96 mt-10'>
                <h3 className='text-md font-bold mb-2'>Your Orders</h3>
                <div className='flex flex-col gap-8 divide-y divide-solid'>
                    {userOrderData.map(userOrderDetail => (
                        <OrderElement key={userOrderDetail.$id}
                            orderId={userOrderDetail.OrderId}
                            products={userOrderDetail.Products}
                            date={new Date(userOrderDetail.OrderDate).toString()}
                            amount={((userOrderDetail.Debited === true) ? "-" : "+") + userOrderDetail.Amount}
                        />
                    ))}
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message="Error getting order data"
                key={vertical + horizontal}
            />
        </>);
}