import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {getProductData} from "../utils/AppWrite";

const SubscriptionElement = ({ subscriptionId, orderId, productId, startingDate, endingDate, cancelled }) => {

    const [status, setStatus] = useState("")
    const [productName, setProductName] = useState("");

    useEffect(() => {

        getProductData(productId).then(productData => {
            setProductName(productData.Name)
        }).catch(e => {
            //TODO: Show Snackbar
        })

        let endingTime = new Date(endingDate).getTime();
        let currentTime = new Date().getTime();

        if (cancelled) {
            setStatus("Cancelled")
        }else if (currentTime > endingTime) {
            setStatus("Expired")
        }else if (endingTime > currentTime) {
            setStatus("Active")
        }

    }, [])

    function cancelUserAccountDialog(dialog) {
        setDialogOpen({ ...dialogOpen, [dialog]: false })
    }

    const [dialogOpen, setDialogOpen] = useState({
        cancelSubscription: false,
    });

    return (
        <>
        <div className='flex pt-4'>
            <div className='flex-grow'>
                <h3 className='text-sm font-semibold mb-2'>{productName}</h3>
                <h3 className='text-xs text-[#4ade80] mb-2'>{status}</h3>
                <h3 className='text-xs'>Expiry date {new Date(endingDate).toLocaleDateString()}</h3>
            </div>
            {((status === "Active") ? <button className='text-sm h-full py-2 font-semibold text-[#f87171] cursor-pointer border px-3 py-0 rounded-md' onClick={() => { setDialogOpen({ ...dialogOpen, deleteOpen: true }) }}>Cancel</button> : <div></div>)}
        </div>

        <Dialog open={dialogOpen.cancelSubscription} onClose={() => cancelUserAccountDialog('cancelSubscription')} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Delete your fixale account</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">To delete your account with all the associated data mail us at support@fixale.com</h3>
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={() => cancelUserAccountDialog('deleteOpen')}>OK</button>
                </div>
            </div>
        </Dialog>
        </>
    );
};

export default SubscriptionElement;
