import Checkout from './pages/CheckoutPage';
import Home from './pages/Home';
import Orders from './pages/view/Orders';
import Subscription from './pages/view/Subscription';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Confirmation from './pages/Confirmation';
import Error from './pages/Error';
import ProtectedRoutes from "./components/ProtectedRoutes";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={ <ProtectedRoutes />} >
          <Route path="/dashboard" element={<Home />}>
            <Route path='orders' element={<Orders />} />
            <Route path='subscription' element={<Subscription />} />
          </Route>
          <Route path="/" element={<Navigate to='/dashboard/orders' />}/>
        </Route>
          <Route path="/" element={<Navigate to='/dashboard/orders' />}/>
          <Route path="/payments" element={<Checkout />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/error" element={<Error />} />v
          <Route path="/checkout" element={<Checkout />} />
      </Routes>
    </Router>
  );
}

export default App;
