import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import Header from './view/Header'
import {
    Outlet
  } from "react-router-dom";

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#0AD7A5',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: '#374151',
        '&.Mui-selected': {
            color: '#374151',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

export default function Home() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue === 0) {
            navigate('/dashboard/orders')
        } else {
            navigate('/dashboard/subscription')
        }
    };

    return (
        <div>
            <Header/>
            <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
                centered>

                <StyledTab label="Orders" />
                <StyledTab label="Subscriptions" />
            </StyledTabs>            
            <Outlet/>         
        </div>
    );
}