import React, {useEffect, useState} from 'react'
import {useSearchParams} from "react-router-dom";
import {getOrderProductsData, getUserOrderData} from "../utils/AppWrite";

function Confirmation() {
    const [urlParams, setUrlParams] = useSearchParams();

    const [orderConfirmationData, setOrderConfirmationData] = useState({
        orderId: "Order #",
        orderConfirmationText: "",
        orderConfirmationDescription: "",
        orderItems: []
    });

    const orderId = urlParams.get("orderId");

    useEffect(() => {
        if (!orderId) {
            setOrderConfirmationData
            ({...orderConfirmationData,
                orderId: ``,
                orderConfirmationText: "Invalid Order. Please Check your Dashboard for Order Status",
                orderItems: []
            })
            return
        }

        getUserOrderData(orderId).then(orderData => {
            if (!orderData) {
                setOrderConfirmationData
                ({...orderConfirmationData,
                    orderId: ``,
                    orderConfirmationText: "Order Data Does not exist",
                    orderConfirmationDescription: "Your Order Does not exist, Check your dashboard for your orders",
                    orderItems: []
                })
                return
            }
            getOrderProductsData(orderData.Products).then(productsData => {
                let orderConfirmationText = "Your Order is being Processed!"
                let orderConfirmationDescription = "Your Order is being Processed. Check your Payment Status after a while on your dashboard."
                if (orderData.PaymentStatus === "success") {
                    orderConfirmationText = "Thank you For your Order!"
                    orderConfirmationDescription = "Your Order has been Processed Successfully!"
                }else if (orderData.PaymentStatus === "failed") {
                    orderConfirmationText = "Uh oh! Your Payment has failed"
                    orderConfirmationDescription = "Your Payment has failed! Please Try Again."
                }else if (orderData.PaymentStatus === "cancelled") {
                    orderConfirmationText = "Payment Cancelled!"
                    orderConfirmationDescription = "Your Order has been Cancelled. If you are facing any issues do contact us."
                }
                setOrderConfirmationData
                ({...orderConfirmationData,
                    orderId: `Order #${orderData.OrderId}`,
                    orderConfirmationText: orderConfirmationText,
                    orderConfirmationDescription: orderConfirmationDescription,
                    orderItems: productsData
                })
            }).catch(e => {
                setOrderConfirmationData
                ({...orderConfirmationData,
                    orderId: ``,
                    orderConfirmationText: "Error",
                    orderConfirmationDescription: "Failed to fetch Order Products Data",
                    orderItems: []
                })
            })
        }).catch(e => {
            setOrderConfirmationData
            ({...orderConfirmationData,
                orderId: ``,
                orderConfirmationText: "Error",
                orderConfirmationDescription: "Failed to fetch your Order Data",
                orderItems: []
            })
        })
    }, [])
    return (
        <div className="overflow-x-hidden relative bg-background-color">
            <div className="flex flex-col justify-center items-center h-full sm:h-screen overflow-hidden">
                <div className="sm:rounded py-6 px-10 w-full sm:w-[55%] md:w-[40%] lg:w-[37%] xl:w-[32%] border sm:bg-white flex flex-col justify-center border-gray-100 divide-y">
                    <div>
                        <h3 className='text-xs text-gray-600 mb-2'>{orderConfirmationData.orderId}</h3>
                        <h3>{orderConfirmationData.orderConfirmationText}</h3>
                        <h3 className='text-sm text-gray-600 mt-2 mb-6'>{orderConfirmationData.orderConfirmationDescription}</h3>
                    </div>
                    <div>
                        {orderConfirmationData.orderItems.map((orderItem) => (
                            <div className='flex mt-6 py-2'>
                                <h3 className='text-xs text-gray-800 grow'>{orderItem.Name}</h3>
                                <h3 className='text-xs text-gray-800'>{orderItem.Description}</h3>
                            </div>
                        ))}
                        <button className='w-full bg-accent rounded-sm text-white px-4 py-1 mt-4'>Go back to dashboard</button>
                    </div>
                </div>
                <h3 className="text-xs text-slate-700 mt-4">&copy; Copyright 2023
                    <script>new Date().getFullYear() {'>'} 2019 && document.write(" - " + new Date().getFullYear());</script>, Fixale Softwares PVT LTD.
                </h3>
            </div>
        </div>
    )
}

export default Confirmation