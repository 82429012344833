import React, {useEffect, useState} from 'react';
import {getOrderProductsData, getProductData} from "../utils/AppWrite";

const OrderElement = ({ orderId, products, date, amount }) => {

    const [productData, setProductData] = useState([]);

    useEffect(() => {
        getOrderProductsData(products).then(productsData => {
            setProductData(productsData);
        }).catch(e => {
            //TODO: Show Snackbar
        })
    }, [])

    return (
        <div className='flex pt-4'>
            <div className='flex-grow'>
                <h3 className='text-sm font-semibold'>#{orderId}</h3>
                <div className="flex mt-1 gap-2">
                    {productData.map((product, index) => (
                        <div key={index} className="rounded border text-xs px-1">{product.Name}</div>
                    ))}
                </div>
                <h3 className='text-xs text-gray-700 mt-1'>{date}</h3>
            </div>
            <div className='text-sm font-semibold'>{amount}</div>
        </div>
    );
};

export default OrderElement;
