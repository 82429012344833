import {getCurrentSession, getUserAccount} from "../utils/AppWrite";
import {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";

function ProtectedRoutes() {

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getUserAccount().then(() => {
            setIsLoading(false);
        }).catch(error => {
            window.location.href = "https://myaccount.fixale.com?next=/payments"
            setIsLoading(false);
        })
    }, [])

    if (isLoading) {
        return (<div></div>)
    }

    return <Outlet />
}

export default ProtectedRoutes;